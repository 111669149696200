<template>
  <section class="section">
    <div class="section-header">
      <h1>{{ $route.name }}</h1>
    </div>
    <div class="row mt-sm-4 justify-content-center">
      <div class="col-12 col-md-12 col-lg-7">
        <div class="card profile-widget">
          <div class="profile-widget-header">
            <img
              alt="image"
              src="assets/img/avatar/avatar-1.png"
              class="rounded-circle profile-widget-picture"
            />
            <div class="profile-widget-items">
              <div class="profile-widget-item">
                <div class="profile-widget-item-label">Caller name</div>
                <div class="profile-widget-item-value">{{ call.caller_name }}</div>
              </div>
              <div class="profile-widget-item">
                <div class="profile-widget-item-label">Caller phone</div>
                <div class="profile-widget-item-value">
                  {{
                    call.caller_phone
                  }}
                </div>
              </div>
              <div class="profile-widget-item">
                <div class="profile-widget-item-label">Status</div>
                <div class="profile-widget-item-value">{{ call.status ? $globalFunc.capitalizeFirstLetter(call.status) : '' }}</div>
              </div>
            </div>
          </div>
          <div class="profile-widget-description">
            <div class="profile-widget-name">
              Customer name
              <div class="text-muted d-inline font-weight-normal">
                <div class="slash"></div>
                {{ call.customer ? call.customer.customer_name : call.caller_name }}
              </div>
            </div>
            <div class="profile-widget-name">
              Customer phone
              <div class="text-muted d-inline font-weight-normal">
                <div class="slash"></div>
                {{ call.customer ? call.customer.phone : call.caller_phone }}
              </div>
            </div>
            <div class="profile-widget-name">
              Customer State
              <div class="text-muted d-inline font-weight-normal">
                <div class="slash"></div>
                {{ call.customer ? $globalFunc.capitalizeFirstLetter(call.customer.state.replaceAll("_", " ")) : '' }} / {{ call.customer && call.customer.lga }}
              </div>
            </div>
            <div class="profile-widget-name">
              Customer Serial Number
              <div class="text-muted d-inline font-weight-normal">
                <div class="slash"></div>
                {{ call.customer && call.customer.serial_number }}
              </div>
            </div>
            <div class="profile-widget-name">
              Comment
              <div class="text-muted d-inline font-weight-normal">
                <div class="slash"></div>
                {{ call.comment }}
              </div>
            </div>
            <div class="profile-widget-name">
              Action
              <div class="text-muted d-inline font-weight-normal">
                <div class="slash"></div>
                <router-link :to="`/tasks/view/${call.task_id}`" v-if="call.task_id">View Task</router-link>
                <router-link :to="`/tickets?ticketId=${call.ticket_id}`" v-else-if="call.ticket_id">View Ticket</router-link>
                <router-link to="#" v-else>None</router-link>
                <br>
                <small v-if="call.action_status !== false">
                  <span class="reduceFont badge" :class="call.action_status == 'Completed' || call.action_status == 'Closed' ? 'badge-success' : 'badge-warning'">{{ call.action_status }}</span>
                </small>
              </div>
            </div>
            <div class="profile-widget-name">
              Date created
              <div class="text-muted d-inline font-weight-normal">
                <div class="slash"></div>
                {{ $globalFunc.getDate(call.created_at) }}
              </div>
            </div>
            <div class="profile-widget-name" v-if="call.status !== 'resolved'">
              Resolve Status
              <div class="text-muted d-inline font-weight-normal">
                <div class="slash"></div>
                <a
                  v-if="call.status !== 'resolved'"
                  class="btn btn-sm text-success"
                  data-toggle="tooltip"
                  title="Resolved"
                  @click.prevent="resolveCall(call.id)"
                  ><i class="fas fa-check"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import CallHelpers from "@/functions/actions/CallRequest";
import { mapState } from "vuex";
export default {
  name: "IncomingCall",
  computed: {
    ...mapState(["loading"]),
  },
  props: {
    callId: {
      type: [String, Number],
      required: true,
    },
  },
  mounted() {
    this.retrieveCall();
  },
  data() {
    return {
      call: {},
    };
  },
  methods: {
    async retrieveCall() {
      const response = await CallHelpers.retrieveIncomingCall(this.callId);
      if (response.status) {
        this.call = response.incomingcall;
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}. Kindly reload the page`,
          "error"
        );
      }
    },
    async resolveCall(callId) {
      this.$confirm("This action cannot be undone. Are you sure?").then(async () => {
        const response = await CallHelpers.resolveCall(callId)
        if (response.status) {
          this.call.status = 'resolved'
          this.showAlert('Success', 'Call status updated', 'success')
        } else {
          this.showAlert('Error occured', response.message, 'error')
        }
      });
    },
  },
};
</script>
<style lang="">
</style>